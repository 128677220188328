import React, { useState, useEffect} from "react"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {Link} from "gatsby"

const Header = (props) => {
  // Menu Modal
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  useEffect(() => {
    const modal = document.querySelector('.l-header');
    if(isMenuModalOpen){
        disableBodyScroll(modal);
    } else {
        enableBodyScroll(modal);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isMenuModalOpen]);

  // Scroll
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
      setScrollY(window.pageYOffset);
  }; 

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScroll(e));
    return () => { 
      window.removeEventListener("scroll", (e) => handleScroll(e));
    };
  }, [scrollY]);

  return (
    <header className={`
        l-header 
        ${isMenuModalOpen ? 'active' : ''} 
        ${scrollY > 0 ? 'l-header-fix' : ''} 
    `} >
        <div className="header__inner">
            <h1><Link className="header__logo" to="/"><img src={'/images/common/logo.svg'} alt="LUCHE GROUP" /></Link></h1>
            <div className="header__block">
                <div className={`header__links ${isMenuModalOpen ? 'header__links--open' : 'header__links--close'}`}> 
                    <nav className="header__language">
                        <Link to="/" className="active">日本語</Link>
                        <Link to="/" className="font-english">EN</Link>
                        <Link to="/">中文</Link>
                    </nav>
                    <nav className="header__menu" >
                        <Link className="spDisplay" to="/">Home<span className="sub">トップページ</span></Link>
                        <Link to="/about_us" className={props.selectedMenu === 'about_us' ? 'active' : ''}>About us<span className="sub">私たちについて</span></Link>
                        <Link to="/services" className={props.selectedMenu === 'services' ? 'active' : ''}>Services<span className="sub">サービス／プロダクト</span></Link>
                        <Link to="/team" className={props.selectedMenu === 'team' ? 'active' : ''}>Team<span className="sub">チームメンバー</span></Link>
                        <a href="https://docs.google.com/forms/d/1FleLJz79ELbxIN-oQh5RW96oCh6scF5zbTYZxn5vd70/viewform" rel="noreferrer" target="_blank" className={props.selectedMenu === 'contact' ? 'active' : ''}>Contact<span className="sub">お問い合わせ</span></a>
                        <Link className="spDisplay" to="/careers">Careers<span className="sub">採用情報</span></Link>
                    </nav>
                    <div className="header__button">
                        <Link to="/careers" className={"btn-common " + (props.selectedMenu === 'careers' ? 'active' : '')}>Careers</Link>
                    </div>
                </div>
                <div className={`header__burger ${isMenuModalOpen ? 'open' : ''}`} 
                  role="button"
                  tabIndex={0}
                  onClick={() => { setIsMenuModalOpen(!isMenuModalOpen); }}
                  onKeyDown={() => { setIsMenuModalOpen(!isMenuModalOpen); }}
                 >
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </header>
  )
}
export default Header
