import React from "react"
import {Link} from "gatsby"

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__inner">
            <div className="footer__col footer__col--col1">
                <Link to="/" className="footer-logo"><img src="/images/common/logo_white.svg" alt="LUCHE GROUP" /></Link>
            </div>
            <div className="footer__col footer__col--col2">
                <ul className="footer__nav-01">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about_us">About us</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/team">Team</Link></li>
                    <li><a href="https://docs.google.com/forms/d/1FleLJz79ELbxIN-oQh5RW96oCh6scF5zbTYZxn5vd70/viewform" rel="noreferrer" target="_blank">Contact</a></li>
                </ul>
            </div>
            <div className="footer__col footer__col--col3">
                <Link to="/careers" className="btn-common btn-common-white">Careers</Link>
            </div>
            <div className="footer__col footer__col--col4">
                <ul className="footer__nav-02">
                    <li><a href="https://giftmall.co.jp/" target="_blank" rel="noreferrer">Giftmall</a></li>
                    <li><a href="https://anny.gift/" target="_blank" rel="noreferrer">Anny</a></li>
                    <li><a href="https://bestpresent.jp/" target="_blank" rel="noreferrer">Best Present</a></li>
                    <li><a href="https://bp-guide.jp/" target="_blank" rel="noreferrer">Best Present Guide</a></li>
                    <li><a href="https://keycase-collection.jp/" target="_blank" rel="noreferrer">ブランドキーケースコレクション</a></li>
                    <li><a href="https://www.magical-trip.com/" target="_blank" rel="noreferrer">Magical Trip</a></li>
                    <li><a href="https://giftization.jp/" target="_blank" rel="noreferrer">Giftization</a></li>
                    <li><a href="https://oiwai.anny.gift/" target="_blank" rel="noreferrer">Annyお祝い体験</a></li>
                </ul>
            </div>
        </div>
        <p className="footer__bottom">© {new Date().getFullYear()} Luche Group All rights reserved</p>
    </footer>
  )
}
export default Footer

