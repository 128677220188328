import React from "react"

import "normalize.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/style.scss"

import Header from "./header"
import Footer from "./footer"

const Layout = (props) => {
  return (
    <main id={props.mainId}>
      <div id="wrapper">
        <Header selectedMenu={props.selectedMenu}/>
        {props.children}
        <Footer />
      </div>
    </main>
  )
}

export default Layout
